// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-browser-deprecation-js": () => import("./../../../src/pages/browser-deprecation.js" /* webpackChunkName: "component---src-pages-browser-deprecation-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-products-payer-quality-of-access-js": () => import("./../../../src/pages/products/payer-quality-of-access.js" /* webpackChunkName: "component---src-pages-products-payer-quality-of-access-js" */),
  "component---src-pages-products-provider-key-accounts-js": () => import("./../../../src/pages/products/provider-key-accounts.js" /* webpackChunkName: "component---src-pages-products-provider-key-accounts-js" */),
  "component---src-pages-products-value-based-models-js": () => import("./../../../src/pages/products/value-based-models.js" /* webpackChunkName: "component---src-pages-products-value-based-models-js" */),
  "component---src-pages-request-demo-js": () => import("./../../../src/pages/request-demo.js" /* webpackChunkName: "component---src-pages-request-demo-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

