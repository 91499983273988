module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#0656D9","theme_color":"#0656D9","display":"minimal-ui","icon":"src/images/pulsedigital-logo-blue-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7e44025cd7447f48fddaafde32afabd9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-heap/gatsby-browser.js'),
      options: {"plugins":[],"appId":"2031595957","enableOnDevMode":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-146409381-1","head":true,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
